import useReadingTime from "@/hooks/useReadingTime";
import { Post } from "@/payload-types";
import { cn } from "@/utils/classname";
import { DotIcon } from "lucide-react";
import { useTranslation } from "next-i18next";

export const PostDuration: React.FC<{
  post: Partial<Post>;
  post_type: "vertical" | "horizontal" | "large-horizontal";
}> = ({ post, post_type }) => {
  const { text } = useReadingTime(JSON.stringify(post?.content || {}));
  const { videoDurationData, type } = post;
  const videoDurationMinutes = videoDurationData?.minutes || "1";
  const { t } = useTranslation();

  var dotIconClass = "";

  const dotVerticalClass = "hidden text-slate-600 lg:block";
  const dotLargeHorizontalClass = "block h-4 w-4 text-slate-600";
  const dotHorizontalClass = "text-slate-600";

  if (post_type === "vertical") {
    dotIconClass = dotVerticalClass;
  } else if (post_type === "horizontal") {
    dotIconClass = dotHorizontalClass;
  } else if (post_type === "large-horizontal") {
    dotIconClass = dotLargeHorizontalClass;
  }

  return (
    <>
      {type === "video" ? (
        <>
          <DotIcon className={cn(dotIconClass)} />
          <div className="text-xs text-slate-500 lg:text-base">
            {videoDurationMinutes} {t("watch")}
          </div>
        </>
      ) : (
        text && (
          <>
            <DotIcon className={cn(dotIconClass)} />
            <div className="text-xs text-slate-500 lg:text-base">
              {text} {t("read")}
            </div>
          </>
        )
      )}
    </>
  );
};

export default PostDuration;
