import { useEffect, useState } from 'react';
import { YouTubeResponse } from '@/@types/youtube';
import { Media } from '@/components/media';
import { Icons } from '@/components/ui/icons';
import useReadingTime from '@/hooks/useReadingTime';
import type { Post } from '@/payload-types';
import formatDate from '@/utils/formatDate';
import { convertISO8601ToMinutesSeconds } from '@/utils/formatISO8601';
import { DotIcon } from 'lucide-react';
import { useTranslation } from 'next-i18next';

import PostDuration from './details/post-duration';

export const PostCardVertical: React.FC<{
  post: Partial<Post>;
  hideFooter?: boolean;
}> = ({ post, hideFooter = false }) => {
  const { t } = useTranslation();

  return (
    <article
      key={post?.id}
      className="space-between sm:min-w-none group flex h-full w-full min-w-[192px] flex-col items-start"
    >
      <div className="relative w-full shadow-xl">
        {post?.type === 'article' ? (
          post.image &&
          typeof post.image !== 'string' &&
          typeof post.image.url === 'string' ? (
            <Media
              resource={post.image}
              className="aspect-[16/9] w-full object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            />
          ) : (
            <img
              src="/images/placeholder.png"
              alt=""
              className="aspect-[16/9] h-full w-full object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            />
          )
        ) : (
          <>
            <div className="absolute inset-0 z-10 flex items-center justify-center">
              <div className=" rounded-full bg-slate-700/80 py-2 pl-2 pr-1">
                <Icons.Play className="h-16 w-16 text-white" />
              </div>
            </div>
            <img
              src={`https://img.youtube.com/vi/${post?.youtube_id}/0.jpg`}
              alt=""
              className="aspect-[16/9] h-full w-full object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            />
          </>
        )}
      </div>
      <div className="flex max-w-xl flex-1 flex-col justify-between gap-y-3">
        <div className="mt-4 items-center lg:mt-8">
          {post?.type && (
            <p className="text-brand-500 text-xs font-semibold uppercase tracking-tight lg:text-lg">
              {t(post?.type)}
            </p>
          )}
          {post?.title && (
            <h2 className="mt-2 line-clamp-2 cursor-pointer whitespace-break-spaces font-serif font-extrabold text-[#193CEB] [text-wrap:balance] group-hover:underline lg:text-2xl">
              {post?.title}
            </h2>
          )}
        </div>

        {!hideFooter && (
          <div className="mt-2 flex flex-col flex-wrap lg:flex-row">
            {post?.publishedOn && (
              <time
                dateTime={post?.publishedOn}
                className="text-xs text-slate-500 lg:text-base"
              >
                {formatDate(post?.publishedOn)}
              </time>
            )}
            <PostDuration post={post} post_type="vertical" />
          </div>
        )}
      </div>
    </article>
  );
};

export default PostCardVertical;
