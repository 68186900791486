function convertTimeMinutesText(time: number) {
  if (time < 0.5) {
    return "1";
  }
  if (time >= 0.5 && time < 1.5) {
    return "1";
  }
  return `${Math.ceil(time)}`;
}

export default convertTimeMinutesText;
