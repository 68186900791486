import type { ReactNode } from 'react';

interface IMainProps {
  meta?: ReactNode;
  children: ReactNode;
  className?: string;
}

const Main = (props: IMainProps) => (
  <>
    {props.meta}
    <main className={`min-h-screen ${props.className}`} id="main">
      {props.children}
    </main>
  </>
);

export { Main };
