import { format, formatRelative, parseISO } from "date-fns";
import { enGB, ms } from "date-fns/locale";

const formatDate = (date: string, locale: string = "en") => {
  return format(parseISO(date), "dd MMM yyyy", {
    weekStartsOn: 1,
    locale: locale === "en" ? enGB : ms,
  });
};

const formatDateRelative = (date: string, locale: string = "en") => {
  return formatRelative(parseISO(date), Date.now(), {
    weekStartsOn: 1,
    locale: locale === "en" ? enGB : ms,
  });
};

export default formatDate;
