import convertTimeMinutes from "./convert-time-to-minutes";
import convertTimeMinutesText from "./convert-time-to-minutes-text";
import readWords from "./read-words";
import stripTags from "./strip-tags";
import stripWhitespace from "./strip-whitespace";

export default function useReadingTime(text: string): any {
  const strippedString = stripTags(stripWhitespace(text));
  const { time, words } = readWords(strippedString);

  return {
    text: convertTimeMinutesText(time),
    minutes: convertTimeMinutes(time),
    words,
    time,
  };
}
